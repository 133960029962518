.section-intro {
  width: 100%;
  // height: 100vh;
  padding-top: 120px;
  margin-bottom: 50vw;
}

.intro-image-container {
  position: relative;
  height: 100%;
}

.intro-pc {
  position: absolute;
  left: 25%;
  top: 0;
  width: 50vw;
  z-index: 8;
}

.intro-iphone {
  position: absolute;
  left: 10vw;
  top: 27vw;
  width: 30vw;
  z-index: 9;
}

.intro-ipad {
  position: absolute;
  right: 10vw;
  top: 20vw;
  width: 30vw;
  z-index: 9;
}

.section-intro-text {
  padding-top: 100px;
  margin-top: 40vw;
  margin-bottom: 84px;
  font-size: 72px;
}

.intro-text-small-header {
  font-size: 0.3em;
  color: #1d1d1f;
  line-height: 1.19;
  font-weight: 600;
  letter-spacing: 0.011em;
  text-align: center;
  margin: 0;
}

.intro-text-title {
  font-size: 1em;
  margin-top: 0.25em;
  color: #1d1d1f;
  line-height: 1.055;
  font-weight: 600;
  letter-spacing: -0.012em;
  text-align: center;
  margin: 0.15em 0 0 0;
}

.intro-text-description {
  font-size: 0.3em;
  margin-top: 0.65em;
  margin-bottom: 1em;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: 0.011;
  text-align: center;
}

.badges-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
  
}


.badges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

.badges-text {
  font-size: 21px;
  color: #1d1d1f;
  line-height: 1.19;
  font-weight: 600;
  letter-spacing: 0.011em;
  text-align: center;
  margin: 0 0 10px 0;

}


.badges-vertical-line {
  background-color: #9898a2;
  width: 2px;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 50px;
}


.badges-images {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  & img {
    max-width: 150px;
    max-height: 48px;
    margin-left: 10px;
    margin-right: 10px;
  }

  & .google-play-store-badge {
    position: relative;

    & .coming-soon {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      .coming-soon {
        display: block;
        max-height: 120px;
      }
    }
  }
}

.home-rounded-card-text {
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;

  & p {
    text-align: start;
    color: white;
    font-size: 48px;
    line-height: 1.0714;
    font-weight: 600;
    letter-spacing: -0.005em;
    margin: 0;
    padding: 0;
  }
}

.home-rounded-card-image {
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-height: 80%;
    max-width: 70%;
    object-fit: cover;
    text-align: center;
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .section-intro-text {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {

  .badges-container{
    flex-direction: column;
  }
  .badges-vertical-line {
    display: none;
  }

  .home-rounded-card-image {
    & img {
      max-height: 90%;
      max-width: 90%;
    }
  }
}

@media only screen and (max-width: 575px) {
  .section-intro-text {
    font-size: 48px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .home-rounded-card-text {
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    & p {
      font-size: 40px;
    }
  }
}
